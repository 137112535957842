@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url("https://use.typekit.net/gxy2kmm.css");

body {
  margin: 0;
  font-family: Poppins, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  font-family: "tt-frantz-b", sans-serif;
font-weight: 400;
font-style: normal;
}


:root {
  --color1:#DE9C57;
  --color2:#dc8911;
  --green1:#bfd9d2;
  --green2:#79b5a4;
  --green3:#498574;
  --wrong:red;
}

.h1, h1 {
font-weight: 900;
}

.bg-green {
  background-color: #dfeae7;
}

.bg-teal {
  background-color: var(--green3);
}

.bg-orange {
  background-color: var(--color2);
}

.ls-1 {
  letter-spacing: 0.125rem
}

.homePageImage {
  background-image: url('img/venice.jpg');
  /* filter: brightness(50%); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 60vh;
  /* color:white; */
}


.matte {
  padding: 25px; /* Inner matte padding */
  background-color: white; /* Color of the matte */
  border: 3px solid white; /* Matte border size and color */
  box-shadow: 0 0 0 12px black; /* Outer frame border size and color */
}
